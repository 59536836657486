import getData from "@/__main__/get-data.mjs";
import type { RouteState } from "@/__main__/router.mjs";
import {
  FORTNITE_PLATFORMS,
  FORTNITE_PLATFORMS_SYMBOLS,
} from "@/game-fortnite/constants/platforms.mjs";
import {
  FORTNITE_PLAYLISTS,
  FORTNITE_PLAYLISTS_SYMBOLS,
} from "@/game-fortnite/constants/playlists.mjs";
import SearchParamsFnEnum from "@/game-fortnite/constants/searchParams.mjs";
import fetchLocker from "@/game-fortnite/fetches/locker.mjs";
import fetchMatch from "@/game-fortnite/fetches/match.mjs";
import fetchPlayer from "@/game-fortnite/fetches/profile.mjs";
import fetchSeasons from "@/game-fortnite/fetches/seasons.mjs";
import ConsoleMatchesValidator from "@/game-fortnite/models/console-matches.mjs";
import MatchlistValidateFn from "@/game-fortnite/models/matchlist.mjs";
import PlayerRanksValidateFn from "@/game-fortnite/models/player-ranks.mjs";
import PlayerSummaryValidateFn from "@/game-fortnite/models/player-summaries.mjs";
import PlayerWeaponStatsValidateFn from "@/game-fortnite/models/player-weapon-stats.mjs";
import * as API from "@/game-fortnite/utils/api.mjs";
import {
  getParams,
  getProfilePageParamsStr,
} from "@/game-fortnite/utils/get-params.mjs";
import { devError } from "@/util/dev.mjs";

// Fetch all the data required for `fortnite/profile/:profileId/:tab?`
async function fetchData(
  [profileId, tab]: Array<string>,
  searchParams: URLSearchParams,
  state: RouteState,
) {
  try {
    const player = await fetchPlayer(profileId);
    const seasons = await fetchSeasons();
    const {
      selectedMode: mode,
      season,
      selectedQueue: queue,
      platform,
    } = getParams(searchParams, {
      season: seasons[0]?.id,
    });
    const key = getProfilePageParamsStr(searchParams);
    const offset = (state.transient?.offset as number) || 0;

    const getPlayerRanks = getData(
      API.getPlayerRanks({ accountId: profileId, seasonId: season }),
      PlayerRanksValidateFn,
      ["fortnite", "playerRanks", profileId, season],
    );

    const getMatches = getData(
      API.getProfileMatchlist({
        accountId: profileId,
        name: player.name,
        seasonId: season,
        isRanked: mode.isRanked,
        isZeroBuild: mode.isZeroBuild,
        mode: queue.backendKey,
        offset,
      }),
      MatchlistValidateFn,
      ["fortnite", "playerMatches", profileId, season, key],
      {
        shouldFetchIfPathExists: offset !== state.transient?.prevOffset,
        mergeFn: (current, next) => {
          const filteredNewValues = next.filter((newMatch) => {
            return !current.find(
              (c) => c.match.gameId === newMatch.match.gameId,
            );
          });
          const allValues = [...current, ...filteredNewValues];
          return allValues.sort(
            (a, b) =>
              new Date(b.match.startedAt).getTime() -
              new Date(a.match.startedAt).getTime(),
          );
        },
      },
    );

    const isConsole =
      platform === FORTNITE_PLATFORMS[FORTNITE_PLATFORMS_SYMBOLS.console].key;
    const getConsoleMatches = getData(
      API.getConsoleMatches(
        {
          accountId: profileId,
          seasonId: season,
        },
        platform,
      ),
      ConsoleMatchesValidator,
      ["fortnite", "consoleMatches", profileId, season, platform],
    );

    const getPlayerSummary = getData(
      API.getPlayerSummary({
        accountId: player.accountId,
        seasonId: season,
        isRanked: mode.isRanked,
        isZeroBuild: mode.isZeroBuild,
        mode: queue.backendKey,
      }),
      PlayerSummaryValidateFn,
      ["fortnite", "profileSummaries", profileId, season, key],
    );

    const getPlayerWeaponStats = getData(
      API.getPlayerWeaponStats({
        accountId: player.accountId,
        isRanked: mode.isRanked,
        isZeroBuild: mode.isZeroBuild,
        seasonId: season,
        mode: queue.backendKey,
      }),
      PlayerWeaponStatsValidateFn,
      ["fortnite", "playerWeaponStats", profileId, season, key],
    );

    const promises = [
      getPlayerRanks,
      getMatches,
      getConsoleMatches,
      getPlayerSummary,
      getPlayerWeaponStats,
      tab === "locker" ? fetchLocker(profileId) : null,
    ];

    const rankMode = mode.isRanked
      ? mode
      : (FORTNITE_PLAYLISTS[mode.rankedPlaylist] ??
        FORTNITE_PLAYLISTS[FORTNITE_PLAYLISTS_SYMBOLS.rankedBattleRoyale]);
    const modified = new URLSearchParams(searchParams.toString());
    modified.set(SearchParamsFnEnum.Mode, rankMode.key);
    const rankedKey = getProfilePageParamsStr(modified);
    if (rankedKey !== key) {
      promises.push(
        getData(
          API.getPlayerSummary({
            accountId: player.accountId,
            seasonId: season,
            isRanked: rankMode.isRanked,
            isZeroBuild: rankMode.isZeroBuild,
            mode: queue.backendKey,
          }),
          PlayerSummaryValidateFn,
          ["fortnite", "profileSummaries", profileId, season, rankedKey],
        ),
      );
    }

    await Promise.all(promises);
    // Transient matches - don't fetch full matches for console
    const gameIds = !isConsole
      ? Object.entries(state.transient?.visibleMatches ?? {})
          .filter(([_, isVisible]) => isVisible)
          .map(([gameId]) => gameId)
      : [];
    for (const gameId of gameIds) {
      try {
        await fetchMatch({ gameId, seasonId: season });
      } catch (e) {
        devError(
          `Failed to fetch transient match for Fortnite with game id ${gameId}`,
          e,
        );
      }
    }
  } catch (e) {
    devError(
      `Failed to fetch initial profile + matchlist (account id: ${profileId})`,
      e,
    );
  }
}
export default fetchData;
