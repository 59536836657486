import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel from "@/__main__/data-model.mjs";

const playerRank = {
  rank: Number,
  promotionProgress: Number,
  track: {
    season: {
      name: String,
      updatedAt: Date,
      endAt: Date,
    },
    rankingType: String,
  },
} satisfies ModelDefinition;

const validateFn = createModel({
  data: {
    player: {
      ranks: [playerRank],
    },
  },
}).transform([playerRank], (response) => response.data.player.ranks);

export type PlayerRanks = FromModel<typeof validateFn>;
export type PlayerRank = FromModel<typeof playerRank>;
export default validateFn;
