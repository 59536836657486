import type { FromModel, ModelDefinition } from "@/__main__/data-model.mjs";
import createModel from "@/__main__/data-model.mjs";

export const playerSummary = {
  wins: Number,
  matches: Number,
  kills: Number,
  downs: Number,
  timesInTopTen: Number,
  topTenPlacements: [
    {
      gameId: String,
    },
  ],
  weaponStats: [
    {
      weapon: {
        key: String,
        name: String,
        rarity: String,
      },
      headshots: Number,
      totalShots: Number,
      kills: Number,
      damageDoneToPlayers: Number,
      criticalDamageDoneToPlayers: Number,
    },
  ],
} satisfies ModelDefinition;

const validateFn = createModel({
  data: {
    playerSummary,
  },
}).transform(playerSummary, (response) => response.data.playerSummary);

export type PlayerSummary = FromModel<typeof validateFn>;

export default validateFn;
